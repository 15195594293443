/* Home-Header */
.header-home {
  display: block;
  background-color: var(--hextech-black);
  max-width: 2160px;
  width: 100%;

  margin: 0px auto;
}

.header-hero{
  padding: 4.5rem 1.5rem;
  height: calc(calc(100vh - 116px) * 0.9);
  width: 75%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* This applies the snap point to the bottom of the header */
  scroll-snap-align: start;

  font-family: 'Beaufort Bold', Arial, sans-serif;
  text-transform: uppercase;
}

.header-hero svg{
  width: 100%;
  margin: 24px 0;
}

.header-hero h4{
  color: var(--gold-2);
  letter-spacing: .025em;
}
.header-home h1 {
  font-size: 5em;
  line-height: 0.85;
  letter-spacing: 0.03em;
  color: var(--gold-1);
  margin: 20px;
}

.role-List {
  margin: 20px;
}

.role-List img {
  height: 75px;
  margin: 0 20px;
}

.role-List img:active {
  transform: scale(2);
}

/* ----------------------------------------- HOME SECTION */
.card-section {
  display: block;
  max-width: 1440px;
  width: 100%;
  margin: 25px auto;

  scroll-snap-align: start;
}

/* ------------------------Search Bar */
:focus {
  outline: none;
}

.header-search {
  background-color: var(--hextech-black-2);
  margin: 0 auto;
  padding: 0 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 80%; */
  z-index: 200;
}

.search-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 3;
}

.search-bar {
  font-family: 'Beaufort Bold', Arial, sans-serif;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--gold-2);

  background-color: var(--hextech-black-2);
  border: 0;
  max-width: 1080px;
  min-width: 360px;

  padding: 32px 16px;
}

.search-bar::placeholder{
  color: var(--grey-2)
}

.champ-icon {
  max-height: 32px;
  width: auto;
  object-fit: cover;
}

.search-filter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 2;
}

.search-region,
.search-role {
  position: relative;
  width: 40%;
}

.region-filter,
.role-filter {
  height: 30px;
  width: 100%;

  border: none;
  border-radius: .5em;
  appearance: none;
  background-color: var(--grey-3);
  color: var(--gold-1);
  padding: 0 20px 0 10px;
  font-size: 12px;
  font-family: 'Beaufort Bold', Arial, sans-serif;
  text-transform: uppercase;
}
.region-filter option,
.role-filter option {
  color: var(--grey-1);
  font-family: 'Beaufort Regular', Arial, sans-serif;
}

.filter-icon {
  position: absolute;
  display: block;
  top: 0;
  right: 0;

  height: 100%;
  width: 20%;
  background-color: var(--grey-cool);

  pointer-events: none;
}

.filter-icon::before,
.filter-icon::after {
  position: absolute;
  --filterSize: .3em;
  content: '';
  width: 0;
  height: 0;

  border-left: var(--filterSize) solid transparent;
  border-right: var(--filterSize) solid transparent;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filter-icon::before {
  border-bottom: var(--filterSize) solid var(--gold-1);
  top: 35%;
}

.filter-icon::after {
  border-top: var(--filterSize) solid var(--gold-1);
  top: 65%;
}

/* --------------------------- Region Info */
.region-info {
  position: relative;
  width: 100%;
  /* max-width: 1440px;
  margin: 0 auto; */
}

.region-info video {
  object-fit: contain;
  /* width: calc(100vw - 32px); */
  /* height: 100vh; */
  width: 100%;
}

.region-info .region-dim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin: auto;
  height: 100%;
  width: calc(100vw + 1em);
  z-index: 2;
  
  background: radial-gradient(ellipse at 50% 0%, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 12, .75) 60%, rgba(10, 10, 12, 1) 80%);
}

.region-overview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: var(--hextech-black-3);
}

.region-short {
  margin: 25px 25px;
  max-width: 500px;
  
  font-family: "Spiegel", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  color: var(--grey-1);
  line-height: 1.75;
}

.region-short p {
  padding-bottom: 1.25em;
}

.region-short p:first-child:first-letter,
.region-short::first-letter {
  float: left;
  line-height: 1.2;
  margin: -2px 20px 0 18px;
  padding: 0 8px 0 10px;
  font-size: 3em;
  color: var(--gold-1);
  border-bottom: 3px solid var(--gold-3);
}

.region-dim .faction-emblem{
  width: 25em;
  margin: auto;
}

.faction-info img{
  width: 100%;
  background: linear-gradient(rgba(10,10,12,.2) 0%, rgba(10,10,12,1) 90%);
}

.faction-info h1{
  font-size: 4.5em;
  line-height: 0.85;
  font-family: 'Beaufort Bold', Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  background: linear-gradient(to top, rgb(103, 71, 31), rgb(203, 172, 98));
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

/* ------------------------------Region Content */
.region-content {
  position: relative;
}

.region-content .region-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  filter: grayscale(100%);
  width: 20%;
  opacity: .2;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.region-splash {
  width: 100%;
  height: fit-content;
}

.region-caption {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 25px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.caption-role {
  max-height: 28px;
  margin: 5px;
}

.region-tag {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: 'Beaufort Bold', Arial, sans-serif;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--gold-1);
}

.region-tag h1 {
  margin: 0 25px
}

.bracket {
  display: flex;
  flex-grow: 1;
  height: 1px;

  margin: 5px
}

.bracket-point {
  border: 1px solid #cdbd91;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  height: 7px;
  transform-origin: 0 0;
  transform: translateY(.5px) rotate(-45deg);
  width: 7px;
}

.bracket-line {
  flex-grow: 1;
  margin-left: 2.22793px;

  width: 279px;
}

.bracket-line.left {
  background: linear-gradient(270deg, #cdbd91 0, rgba(0, 0, 0, 0));
}

.bracket-line.right {
  background: linear-gradient(90deg, #cdbd91 0, rgba(0, 0, 0, 0));
}

/* ----------------------- Loading  */
.card-load {
  margin: 2em;
}

.card-noload {
  margin: 50px 0px;
  padding: 0px 20px;
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 20px;
}

/* Home Footer */
.card-footer {
  background-color: var(--grey-3);
  font-family: "Spiegel", sans-serif;

  max-width: 2160px;
  width: 100%;
  margin: 0px auto;
  display: block;

  scroll-snap-align: start;
}

.copyright-content {
  margin: 0 auto;
  /*   max-width: 1080px; */
  font-weight: 500;
  font-size: 1em;
  line-height: 1.75;
  text-align: center;
  word-break: keep-all;
  color: var(--gold-1);

  display: flex;
}

.copyright-section {
  flex: 1 1;
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.copyright-info {
  margin: 20px;
}

.copyright-author {
  color: var(--grey-1);
  height: 5vh;
  background-color: var(--hextech-black);
  font-size: .8em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-section.about {
  flex: 1;
}

.copyright-section.contact {
  flex: 1;
  text-transform: uppercase;
}

.copyright-section.links {
  flex: 2.5;
}

.copyright-section .about-logo {
  width: 20em;
  height: auto;
  margin-bottom: 20px;
}

.copyright-section.about p,
.copyright-section.links p {
  font-size: .85em;
  line-height: 120%;
}

.copyright-section.links .links-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.copyright-section.links img {
  width: 5em;
  height: auto;
  aspect-ratio: 3/2;
  object-fit: contain;
  /* mix-blend-mode: color-burn; */
}

.copyright-section.contact .contacts {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  padding: 10px;
}

.copyright-section.contact .contacts>a {
  margin: .25em;
  fill: #7e7e7e;
}

.copyright-section.contact .contacts>a:hover {
  fill: #f9f9f9;
}