main,
header,
article,
aside,
details,
figcaption,
figure,
footer,
hgroup,
menu,
nav,
section {
    display: block;
}

.main-container {
    display: block;
    height: 100%;
    width: 100%;
}

.appws {
    background-color: red;
}

img,
svg {
    /* Prevents dragging of image and svg */
    user-select: none;
    -webkit-user-drag: none;
}

section {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: block;
}

.page-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.dim.off {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .45);

    transition: all .5s ease;
}

.dim.on {
    background: rgb(0, 0, 0);
    transition: all .5s ease;
}

.focusChamp {
    position: absolute;
    background: transparent;

    z-index: 10;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    margin: auto;
    border-radius: 50%;
    height: 4em;
    width: 4em;
    border: none;

    box-shadow: 0 30px 40px rgba(255, 255, 255, 0.1);
}

.focusChamp:hover {
    background-color: rgba(255, 255, 255, 0.4);
    --webkit-backdrop-filter: blur(5px);
}

.focusOn {
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;
}

.focusOut {
    opacity: 1;
    visibility: visible;
    transition: all .4s ease;
}


.page-wrap .champ-show {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
}

.champ-show::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(12px) brightness(50%); 
    backdrop-filter: blur(10px);

    transition: opacity .5s; 
    transition-delay: 100ms;
}

.champ-show .champ-splash {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    transition: opacity .5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
    height: 100vh;
}

.splash-in {
    animation: splashIn .25s;
}
  
@keyframes splashIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.champ-show.fade-out::before {
    opacity: 0;
}

.champ-splash.skin-active {
    opacity: 1;
    z-index: 1;
    transition-delay: 0s;
}

.transbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 60%, rgba(10, 10, 12, .1) 70%, #0a0a0c 99%);
    transition: all 0.5s ease;
}

.transbox.on {
    /* -webkit-mask-image: linear-gradient(rgb(0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%); */
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 100%, rgba(10, 10, 12, .1) 0%, #0a0a0c 100%);
    transition: all 0.5s ease;
}

.champ-image {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100vh;
    display: block;
    /* -webkit-mask-image: linear-gradient(rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 99%); */
    transition: all 0.5s ease;
}

.champ-image > * {
    /* width: 100%; */
    height: 100%;
    display: block;
}
.champ-image img {
    min-width: 1280px;
    object-fit: contain;
    transition: opacity 1s ease-in-out;
}

.champ-image img.off {
    opacity: 0;
}

/*CHAMP DETAIL*/
.champ-detail {
    position: absolute;
    bottom: 5%;
    z-index: 5;
    margin: 0 auto;
    text-align: center;
    left: 0;
    right: 0;

    width: 75vw;
    /* width: clamp(70vw, 75vw, 75vw); */
    max-width: 75vw;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    transition: all .6s ease-out;
    min-height: 0;
}

.champ-detail.active {
    min-height: 90%;
    transition: min-height .6s ease-out;
}

.champ-detail .champ-divide {
    width: 100%;
    height: auto;
}

.champ-naming {
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-family: "Beaufort Bold", serif;
    text-transform: uppercase;
    color: #fff;
        /* cursor: pointer; */
        /* cursor: url('https://raw.communitydragon.org/pbe/game/assets/ux/cursors/hand2.png') 0 0, auto */
}

.champsTitle {
    display: block;
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.1em;

    color: var(--gold-2);
    letter-spacing: 4px;
    padding: 10px 0 5px 0;
}

.champsName {
    display: block;
    font-size: 5em;
    line-height: 0.85;
    font-style: italic;
    letter-spacing: 0.03em;
    color: var(--gold-1);
    /* filter: drop-shadow(0 0 0.8rem rgb(26, 90, 241)); */
}

/* CHAMP BIO */
.champ-extend {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /* position: relative; 
    margin: 25px;*/

    position: absolute;
    transition: all 0.5s ease-in-out;
    transform: translateY(100%);
    opacity: 0;
}

.champ-detail.active .show-extend {
    transform: translateY(50%);
    opacity: 1;
    display: flex;
    transition: all 0.5s ease-in-out;
}

.bio-center {
    height: 55vh;
    min-width: 40vh;
    max-width: 80vh;
    margin: 0;
    padding: 0;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.bio-quote {
    background-color: var(--hextech-black-2);
    border: 2px solid var(--gold-5);

    margin: 0 0 10px 0;
    flex: 1;
    min-height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    color: var(--grey-1);
    font-size: .9em;
    line-height: 1.5;
}

.bio-quote button {
    height: 100%;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--hextech-black-2);
    border: 1px solid var(--gold-5);

    opacity: 0;
    /* cursor: pointer; */
    transition: all .3s ease-out;
}

.bio-quote button:hover {
    border: 2px solid var(--pick);
    opacity: 1;
}

.bio-quote button.playing {
    border: 2px solid var(--pick);
    opacity: 1;

    transition: all .3s ease-in-out;
}

.bio-quote button:hover span,
.bio-quote button.playing span {
    color: var(--pick);
}

.bio-quote button span {
    rotate: 90deg;
    width: max-content;

    z-index: 0;
    
    text-transform: uppercase;

    pointer-events: none;
    /* to prevent span tag to block button tag to be clicked */
}

.bio-quote-info {
    margin: 25px 0 0 0;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.5;
    font-size: .8em;
}

.bio-quote-info p {
    font-family: "Spiegel", sans-serif;
}

.bio-quote-info span {
    color: var(--gold-2);
}

.bio-description {
    background-color: var(--hextech-black-2);
    border: 2px solid var(--gold-5);
    padding: 15px;
    max-width: 75vw;
    box-sizing: border-box;
    flex: 2 1;
    background-position: 50%;

    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    z-index: 1;
}

.bio-description::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-image: var(--bg);
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(.3) grayscale(100%);
    z-index: -1;
    opacity: .4;
}

.champ-more {
    font-family: "Beaufort Regular", Arial, sans-serif;
    font-size: 12px;

    -webkit-border-image: -webkit-linear-gradient(top, var(--gold-3), var(--gold-4)) stretch;
    border-image: linear-gradient(180deg, var(--gold-3), var(--gold-4)) stretch;
    border-width: 1px;
    border-style: solid;

    background-color: var(--hextech-black-2);
    border-image-slice: 1;

    color: var(--gold-4);
    /* cursor: pointer; */

    letter-spacing: 2px;
    padding: 12px 24px;
    text-transform: uppercase;
    margin-top: 20px;
}

.champ-more:hover{
    color: var(--gold-3);
}

.champ-lore {
    font-family: 'Spiegel', sans-serif;
    font-size: .75em;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 1.5;

    text-align-last: center;
    color: var(--grey-1);
    text-align: justify;
}

.champ-lore p::first-letter {
    font-family: 'Beaufort Bold', Arial, sans-serif;
    color: var(--gold-1);
    font-size: 3em;
    border-bottom: 3px solid var(--gold-4);
    float: left;
    line-height: 1.2;
    margin: -2px 20px 0 18px;
    padding: 0 8px 0 10px;
}

.bio-side {
    position: absolute;
    bottom: 0;
    z-index: 2;

    transition: all 0.5s ease-in-out;
}

.bio-role {
    height: 55vh;
    min-width: 40vh;
    max-width: 80vh;

    left: 0;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    user-select: none;
    cursor: pointer;

    perspective: 1000px;
    /* 3D effect */
}

.role-title,
.combat-title {
    width: 100%;
    border-bottom: 2px solid var(--gold-5);

    font-size: 12px;
    color: var(--gold-1);
    letter-spacing: 2px;

    text-transform: uppercase;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
    justify-content: center;
}

.role-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

.bio-role.combat>.role-combat-card {
    transform: rotateY(180deg);
}

.role-combat-card {
    height: 100%;
    width: 100%;

    position: relative;
    transition: transform 800ms;
    transform-style: preserve-3d;

    font-family: 'Beaufort Bold', Arial, sans-serif;

    background-color: var(--hextech-black-2);
    border: 2px solid var(--gold-5);
}

.role-front,
.combat-back {
    height: 100%;
    width: 100%;

    position: absolute;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    display: flex;
    flex-direction: column;
}

.combat-back {
    transform: rotateY(180deg);
}

.role-info {
    padding: 20px;
    box-sizing: border-box;
}

.role-info img {
    height: 4vw;
    min-width: 2vw;
}

.role-info .role-label {
    color: var(--grey-1);
    line-height: 1.5;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: .55em;
    text-transform: uppercase;
}

.show-left {
    transform: translateX(-105%);
}


.bio-region {
    height: 55vh;
    /* hanapin ung sumisira sa border */
    width: 40vh;
    min-width: 40vh;
    max-width: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: absolute;
    right: 0;

    -webkit-backdrop-filter: blur(8px) saturate(200%);
    backdrop-filter: blur(8px) saturate(200%);
    background-color: transparent;
}



.region-container {
    position: relative;
    flex: 3;

    background-color: var(--hextech-black-2);
    border: 2px solid var(--gold-5);
    border-bottom: 0;
}

.region-emblem {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    margin: 5px auto;
    height: 25vh;

    z-index: 1;
    transition: all 600ms;
}

.region-container:hover>.region-emblem {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
}

.region-container:hover>.region-show {
    filter: brightness(1) grayscale(0%);
    transition: all 800ms;
}

.region-show {
    height: 100%;

    background-image: var(--regionSplash);
    background-position: 50%;
    background-size: cover;
    min-height: 150px;

    -webkit-filter: brightness(.3) grayscale(100%);
    filter: brightness(.4) grayscale(100%);
    opacity: .4 !important;

    transition: all 800ms;

}

.region-title {
    display: flex;
    min-height: 50px;
    z-index: 3;
    background-color: var(--hextech-black-2);
    border: 2px solid var(--gold-5);
}

.region-name {
    display: flex;

    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;

    flex-direction: column;
    flex-grow: 1;

    border-right: 2px solid var(--gold-5);
}

.region-name h5 {
    font-family: 'Beaufort Regular', Arial, sans-serif;

    color: var(--gold-3);
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.region-name h6 {
    font-family: 'Beaufort Regular', Arial, sans-serif;

    color: var(--gold-1);
    font-size: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.region-faction {
    flex-shrink: 1;
    padding: 8px 12px;
}

.region-faction img {
    display: block;
    max-height: 30px;
    max-width: 30px;
}

.bio-related {
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-family: "Beaufort Bold",Arial,sans-serif;

    margin: 10px 0 0 0;
    color: var(--gold-1);
    font-size: .5em;

    border: 2px solid var(--gold-5);
}

.related-title {
    width: 100%;

    flex: 1 1;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 5px 0;

    font-size: 1em;
    color: var(--gold-1);
    letter-spacing: 2px;
    text-transform: uppercase;

    background-color: var(--hextech-black-2);
    border-bottom: 2px solid var(--gold-5);
}

.bio-related .related-container {
    flex: 3;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;
    background-color: var(--hextech-black-2);
    padding: 10px;
    margin: 0 10px;

    overflow: hidden !important;
    box-sizing: border-box;
}

/* Override SwiperJS */

.related-container>.swiper-wrapper {
    justify-content: space-between;
    margin: auto;
    width: auto;
    height: auto;
}

.related-container>.swiper-wrapper>.swiper-slide {
    /* flex-shrink: unset; width: 100%; */
    width: unset !important;
}

.related-container>.swiper-wrapper>.swiper-slide.swiper-slide-active {
    scale: 1;
    margin: 0;
}

.related-container>.swiper-wrapper>.swiper-slide>.avatar-icon {
    /* display: flex;
    flex-direction: column-reverse; */

    box-shadow: none;
    filter: brightness(.5);

    /* background-color: #ccc;
    width: 50px;
    height: 50px;*/

}

.related-container>.swiper-wrapper>.swiper-slide>.avatar-icon:hover {
    scale: 1.1;
    filter: brightness(1);

}

.related-container>.swiper-wrapper>.swiper-slide>.avatar-icon .avatar-container {
    height: 3rem;
    width: 3rem;
    position: relative;
}

.avatar-container .avatar-img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 50%;
}

.related-container>.swiper-wrapper>.swiper-slide>.avatar-icon .avatar-container::after {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    background-image: linear-gradient(180deg, #3e3310, #c6b98b, #3e3310);
    border-radius: 50%;
    content: "";
    z-index: -1;
}

.related-container>.swiper-wrapper>.swiper-slide>.avatar-icon p {
    margin: 2px 0;
    opacity: 0;
    font-size: .45em;
    text-transform: uppercase;
    letter-spacing: .25em;
    text-align: center;
}

.related-container>.swiper-wrapper>.swiper-slide>.avatar-icon:hover p {
    opacity: 1;
}



.champLink {
    text-decoration: none;
    color: inherit;
    display: block;
}

.show-right {
    transform: translateX(105%);
}

.bio-toggle {
    z-index: 3;
    position: absolute;
    top: 40%;

    border-radius: 50%;

    padding: 3px;
    margin-right: 0 !important;

    /* cursor: pointer; */
    transition: all 0.5s ease-in-out;
}

.bio-toggle.show-top {
    top: -11%;
    transition: all 0.5s ease;
}

.avatar-icon {
    border-radius: 50%;
    box-shadow: 0 0 30px #0a0a0c;
    position: relative;

    height: auto;
    /* 75px */
    width: 75px;

    z-index: 1;
}



.avatar-container::after {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;

    background-image: -webkit-linear-gradient(top, #3e3310, #c6b98b, #3e3310);
    background-image: linear-gradient(180deg, #3e3310, #c6b98b, #3e3310);
    border-radius: 50%;
    content: "";
    z-index: -1;
}


.avatar-container {
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    /* background-image: url("https://images.contentstack.io/v3/assets/blt187521ff0727be24/blt359f81b2f47d8434/60ee11234be40c60fcda6d20/champion-pyke-splash.jpg"); */
    background-position: 50% 50%;
    background-size: 108%;
    background-repeat: no-repeat;
    opacity: 1;
    max-height: 75px;
    max-width: 75px;
    top: 17%;
    left: 17%;
    /*  CENTER YUNG AVATAR IMAGE   */
}

.avatar-icon .icon-frame {
    display: block;
    z-index: 5;
    position: relative;

    height: 75px;
    width: 75px;
}


/* Champ Open */
.champ-dim {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-image: radial-gradient(ellipse closest-side at 50% 30%,#1b2528,#0a0a0c);
    /* transform: scale(0); */
    visibility: hidden;
    transition: all .5s ease;
}

.champ-detail.active .champ-dim {
    /* transform: scale(1); */
    visibility: visible;
    opacity: .75;
    transition: all .5s ease-out;
}

/*--------------------SKILLS--------------------*/
.skill-show {
    position: absolute;
    top: 45%;
    z-index: 20;
    left: 2%;

    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 0 40px; */
}

.skill-show .skill-dim {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: rgba(0, 0, 0, .8);

    visibility: hidden;
    opacity: 0;
    transition: all .4s ease;
}

.skill-show .skill-container {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);

    position: relative;

    font-family: 'Beaufort Bold', Arial, sans-serif;
}

.skill-container .skill-toggle,
.skill-container span .icon-skill {
    height: 55px;
    width: 55px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 40%;
}

.skill-container .skill-toggle {
    font-weight: 500;
    font-style: italic;
    text-transform: uppercase;
    color: var(--gold-2);

    font-size: 2em;

    z-index: 50;
    transform: rotate(90deg);
    /* cursor: pointer; */
    transition: transform 0.6s ease;
    visibility: hidden;
}

.skill-toggle.show{
    visibility: visible;
}

.skill-container #indicator {
    position: absolute;
    width: 64vh;

    transform: rotate(-90deg);
    /* Set default sa Passive */
    transition: all .4s ease-out;
    z-index: 0;
}

#indicator::before {
    content: '';
    width: 90px;
    height: 90px;
    border-radius: 50%;
    position: absolute;

    transition: all .2s ease-in;
    filter: drop-shadow(0 0 .5em rgb(208, 198, 93));
    opacity: 0;

    top: 0;
    left: 26vh;
    transform: translate(0, -50%);

    background-image: url("../assets//indicator.png");
    background-size: 100px;
    background-position: 45% 5%;
}

.skill-show.active #indicator::before {
    opacity: 1;
    /* background-color: rgba(57, 64, 72, .8); */
    top: -50%;
    left: 0;
    transform: translate(-50%, -50%);
}

.skill-show.active .skill-container .skillFrame {
    display: block;
    /* Bug fix: Abilities invisible Div block */
}

.skillFrame .frame-outer,
.skillFrame .frame-inner{
    position: absolute;
    top: 50%;
    left: 50%;

    visibility: hidden;
    transform: translate(-50%, -50%);
}

.skillFrame .frame-inner {
    width: 55vh;
    height: 55vh;
    /* rotate(90deg); */
    transition: all .5s linear;
}

.skillFrame .frame-inner {
    width: 45vh;
    height: 45vh;
    /* rotate(270deg); */
    transition: all .1s ease-out;
}

.skill-show.active .skillFrame .frame-inner,
.skill-show.active .skillFrame .frame-outer {
    visibility: visible;
}


@keyframes fullRevolve {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes innerSet {
    0% {
        transform: translate(-50%, -50%) rotate(360deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-90deg);
    }
}

.skillFrame::after {
    content: '';
    position: absolute;
    /* position:fixed; */
    display: none;
    /* Bug fix: Abilities invisible Div block */

    /* border-left: 5px solid var(--goldText); */
    border-radius: 50%;

    width: calc(100% + 5px);
    height: calc(100% + 5px);

    /* To make after overlaid center */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    filter: drop-shadow(0px 0px 8px var(--goldText));
}

.skill-show.active .skill-container .skillFrame {
    transform: rotate(-90deg);
    /* Set default sa Passive */
}

.skill-show.active .skill-container .skillFrame::after {
    display: block;
}

.skillLine {
    width: 0;
    height: 5px;

    background-color: transparent;
    /*#0a0a0c;*/

    z-index: -2;
    opacity: 0;
}

.skillLine::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 0;
    height: 100%;
    background: var(--gold-4);

    transition: all 5s linear;
}

.skill.active~#indicator .skillLine::before {
    animation: showLine .5s linear forwards;
}

.skill-show.active #indicator .skillLine {
    opacity: 1;
    width: 200px;
}

@keyframes showLine {
    0% {
        filter: drop-shadow(0px 0px 0px var(--gold-4))
    }

    100% {
        width: 100%;
        filter: drop-shadow(0px 0px 4px var(--gold-3))
    }

}

.skill-container span {
    position: absolute;
    transition: all 0.4s ease;
    opacity: 0;
}

.skill {
    filter: drop-shadow(0 0 0.6rem black);
    border: .25em solid var(--gold-3);
    border-radius: 50%;
}

.skill.active {
    filter: drop-shadow(0 0 1rem --gold-3);
}

.skill-container span .icon-skill {
    /* transform: rotate(45deg); */
    transform: rotate(180deg);

}

.skill .extraskill-container {
    position: absolute;
    top: 0;

    visibility: hidden;
    opacity: 0;

    transition: all .25s ease;
    transform: rotate(270deg) scaleX(-1);
}

.skill.active .extraskill-container {
    visibility: visible;
    opacity: 1;
}

.extraskill {
    transition: all .5s ease;
}

.skill.active .extraskill-container .extraskill {
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(calc(var(--i) * (360deg/-6))) translateY(12vh);
}


.extraskill-container .extraskill img {
    height: 2.3em;
    width: 2.3em;
    rotate: calc(var(--j) * 45deg + 90deg);

    border: 1.5px solid var(--gold-3);
    border-radius: 50%;
    filter: grayscale(.8);
    clip-path: circle(50%);

    transition: all .2s;
    transform: rotate(calc((var(--i) * (360deg/6)))) scaleX(-1);
}

.extraskill.active img,
.extraskill:hover img {
    filter: grayscale(0);
    height: 2.5em;
    width: 2.5em;
}

.skill-container span .icon-skill img {
    height: 3.75em;
    width: 3.75em;
    transform: rotate(calc(var(--i) * (360deg/-8)));
    transition: .2s;
    filter: grayscale(.8);

    clip-path: circle(50%);
}

.skill-container span .icon-skill:hover img,
.skill-container span.active .icon-skill img {
    filter: grayscale(0);
}

/* SKILL OPEN */

.skill-show.active .skill-dim {
    /* transform: scale(1); */
    visibility: visible;
    opacity: 1;
    transition: all .4s ease;
}

.skill-show.active .skill-toggle {
    transform: rotate(180deg) scale(0);
    /* background-color: red; */
}

.skill-show.active .skill-container span {
    transform: rotate(calc(var(--i) * (360deg/8))) translateY(24vh);
    opacity: 1;
}

.skill-show.active .skill-container span.active {
    /*active skill mas mataas  */
    transform: rotate(calc(var(--i) * (360deg/8))) translateY(32vh) scale(1.1);
    z-index: 5;
}

.skill-ring {
    scale: 0;
    fill: rgba(255, 255, 255, 0.6);
    animation: 180s linear 0s infinite normal forwards running AbilityRing;
}


.skill-show.active .skill-ring {
    position: absolute;
    width: 65vh;
    height: 65vh;
    scale: 1;
    transition: all .3s linear;
}

@keyframes AbilityRing {

    0% {
        transform: scale3d(0.985, 0.985, 1) rotate(0deg);
    }

    50% {
        transform: scale3d(1, 1, 1) rotate(180deg);
    }

    100% {
        transform: scale3d(0.985, 0.985, 1) rotate(360deg);
    }
}


.skill-show .skill-display {
    scale: 0;
    opacity: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: calc(20vw);

    transition: all 0.4s ease;
    animation: slide-out-left 0.4s ease-in both;
}

.skill-show.active .skill-display {
    opacity: 1;
    scale: 1;

    transition: all 0.4s ease;
    animation: slide-in-left-show 0.4s ease-in both;
}

/* Skill Display Animation */
@keyframes slide-out-left {
    0% {
        transform: translateX(100vh) rotateY(0) scale(1);
        transform-origin: center;
        opacity: 1;
    }

    100% {
        transform: translateX(0) rotateY(30deg) scale(0);
        transform-origin: center;
        opacity: 1;
    }
}

@keyframes slide-in-left-show {

    0% {
        transform: translateX(0) rotateY(30deg) scale(0);
        transform-origin: center;
        opacity: 1;
    }

    100% {
        transform: translateX(100vh) rotateY(0) scale(1);
        transform-origin: center;
        opacity: 1;

    }
}

.skill-overlay {
    position: absolute;
    top: 0;
    left: 0;

    aspect-ratio: 1.47 / 1;
    height: auto;
    width: 100%;
    z-index: 2;
    object-fit: cover;

    pointer-events: none;
    overflow: hidden;
}


.skill-tab {
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    width: 30vw;
    opacity: 0;
}

.skill-tab * {
    font-family: inherit;
    text-align: center;
    color: white;
}

.skill-tab h3 {
    text-transform: uppercase;
}

.skill-tab .skill-image {
    height: 50px;
    margin: auto auto 10px auto;
    width: 50px;

    padding: 2px;
    border: 2px solid var(--gold-3);
}

.skill-tab.active-tab {
    opacity: 1;
    z-index: 1;
    padding: 10px;
    transition: all 0.6s ease;

    /* From https://css.glass */
    background: rgba(10, 10, 12, 0.5);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5.6px);
    backdrop-filter: blur(5.6px);
}

.skill-description {
    width: 90%;

    background-color: var(--grey-3); /* #0d161a  */
    border: 2px solid var(--gold-3);
    padding: 10px;

    font-family: 'Beaufort Regular', Arial, sans-serif;
    font-weight: 200;
}

.skill-description h5 {
    font-family: 'Beaufort Bold', Arial, sans-serif;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: .1em;
}

.skill-description p {
    font-size: .7em;
    text-align: center;
    color: var(--grey-1);
}


/* VIDEO ERROR */
.vid-show {
    position: relative;
    width: 30vw;
    height: 100%;
    box-sizing: border-box;

    padding: 2%;
    margin: 0 0 20px 0;
}

.video-container {
    position: relative;
/*     width: 508px;
    height: 346px;
 */}

.video-container::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-url) no-repeat center center;
    background-size: 100% 100%;
}

.no-video {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    border-radius: 10%;
}

.video-container .no-display {
    height: auto;
    width: 100%;
    /* aspect-ratio: auto 426 / 240; */
    transform: translate(0%, 0%);
    margin: 0;
}

.no-sign {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-pic {
    display: block;
    width: 50%;
    margin: 0px auto 15px;
}

.no-text {
    font-size: x-small;
    font-family: 'Beaufort Bold', Arial, sans-serif;
    font-style:normal ;
    text-transform: uppercase;
}

.has-video {
    height: 100%;
    width: 100%;
    border-radius: 10%;
}


/*SKIN CONTAINER*/
.skin-component{
    font-family: 'Beaufort Bold', Arial, sans-serif;
    text-transform: uppercase;
}

.skin-toggler {
    position: absolute;
    z-index: 20;
    right: 0%;
    top: 45%;

    font-style: italic;
    color: var(--gold-2);
    justify-content: center;
    font-size: 2em;

    transition: transform .5s ease, right .5s ease;
    transform: rotate(90deg);
}

.skin-logo {
    height: 50px;
}

.skin-show {
    position: fixed;
    height: 100%;
    width: 18%;
    right: -18%;

    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all .5s ease;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    /* overflow: hidden; */
    background: linear-gradient(to left, rgba(0,0,0,.9) 50%, transparent 100%);

    /* background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8)80%, transparent 100%); */
}

.skin-component .skin-dim{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: radial-gradient(circle at 55% center, rgba(255, 255, 255, 0) 35%, rgba(1,10,19,.85) 70%);
    /* background: radial-gradient(circle, rgba(255,255,255,0) 45%, rgba(0,0,0,1) 75%); */

    visibility: hidden;
    opacity: 0;

    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 5;
}

.skin-component.active .skin-dim{
    visibility: visible;
    opacity: 1;
    transition: opacity .4s ease;
}

.skin-info{
    width: 24%;
    height: auto;
}

.skin-info div{
    display: flex;
    align-items: center;
    height: 2.5em;
    width: max-content;
    margin: 5px 0;
}

.skin-info div .skin-icon{
    margin-right: 10px;
    height: 45%;
    width: max-content;
}

.skin-info h3{
    color: var(--gold-1);
}

.skin-info p{
    font-family: 'Spiegel',  sans-serif;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.75 ;
    text-transform: none;
    color: #9b988c;

    padding-top: 15px;
    padding-right: 10px;

    border-top: 1px solid var(--gold-4);
}

.skin-feature{
    font-family: 'Beaufort Regular', Arial, sans-serif;
    font-weight: 600;
    font-size: .725em;
    line-height: 1.55 ;
    text-transform: none;
    color: #9b988c;

    margin-left: 20px;
    display: block;
}

.skin-addon{
    display: flex;
    margin-top: auto;
    /* justify-content: flex-start; */
    align-items: center;
}

.addon-hide, 
.addon-center{
    height: 20px;
    width: 24px;
    padding: 4px;
    background-color: var(--grey-3);
    border: .5px solid var(--gold-4);
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 5px;
    fill: var(--gold-1);
    fill-rule: evenodd;
}

.addon-hide:hover, 
.addon-center:hover{
    fill: var(--gold-2);

}

.addon-center svg{
    transform: scale(1);
}

.addon-center.centered {
    fill: var(--gold-3);
}

.addon-center.centered svg{
    transform: scale(.75);
}

.addon-hide svg g{
    fill: var(--gold-1);
    stroke: #11181f;
    stroke-width: 4px;
}

.skin-swiper {
    position: relative;
    box-sizing: content-box;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    margin: 0 15px 0 0;
}

/*?the fuck this thing do?
.skin-swiper .skinType{
    position: absolute;
    top: 48%;
    //left: 50%; 
    transform: translate(-50%, -50%);
}*/

.skin-slide {
    display: block;
    margin: 5px auto;
    text-align: center;
    /* cursor: pointer; */
    padding: 5px;
}

.skin-slide div {
    box-sizing: border-box;
    position: relative;

    width: 100%;
    padding: 0;
    background-color: transparent;
    /* display: flex; */
    -webkit-box-align: center;
    align-items: center;

    font-family: inherit;
    font-size: xx-small;
    color: var(--grey-1);
    text-transform: uppercase;
    overflow-wrap: break-word;
}

.skin-slide * .skinView img {
    object-fit: cover;
    height: 7em;
    max-width: 100%;
    width: auto;
}

.skin-toggler.active {
    right: 15%;
}

.skin-toggler.hide{
    visibility: hidden;
}

.skin-show.active {
    right: 0;
    transition-timing-function: ease;
    /* transition-timing-function: cubic-bezier(0, 0.5, 0.1, 1.15); //bouncy look*/
}

.swiper {
    height: auto;
    overflow: visible !important;
}

.swiper-slide {
    height: 100%;
}

.swiper-slide .skinView img {
    border: 2px solid var(--grey-2);
    filter: grayscale(50%);
}

.swiper-slide.swiper-slide-active {
    margin: 20px 0 20px 0;
    scale: 1.175;
}

.swiper-slide.swiper-slide-active .skinView img {
    border-image: linear-gradient(var(--gold-4), var(--gold-3)) 30;
    border-width: 2px;
    border-style: solid;

    filter: grayscale(0%);
}

.skinView span {
    font-size: .75em;
    letter-spacing: .1em;
}

.swiper-slide.swiper-slide-active .skinView span {
    font-size: .9em;
    color: var(--gold-1);
}

.skinRarity {
    position: absolute;
    top: -5%;
    left: 50%;
    transform: translateX(-50%);

    height: 10px;
    width: 10px;
}


/*-----------------------------------------------------------------------------------------------------Navigation */
.nav-bar {
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -ms-flex-align: center;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    height: 65px;
    background-color: var(--hextech-black);
    box-shadow: 0 -8px 10px 8px #0a0a0c;

    font-family: 'Beaufort Bold', Arial, sans-serif;

    z-index: 10;
    opacity: 0;
    transition: all .5s ease;
}

.nav-divider {
    height: 75%;
    border-left: 2px solid var(--gold-5);
}

.nav-bar:hover {
    opacity: 1;
    transition: all .5s ease;
}

.nav-main {
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    -webkit-box-align: center;
    padding: 0 30px;
    margin: 0 25px 0 0;
    height: 100%;

    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;

    border: 2px solid var(--gold-5);
    border-top: 0;
    border-bottom: 0;
}

.back-nav {
    height: 31px;
    max-width: 31px;
    min-width: 31px;
    padding: 6px;
    
    border: 1px solid var(--gold-4);
    border-radius: 50%;
    background-color: transparent;
    
    cursor: pointer;
    transition: background-color .3s;
}

.back-nav svg {
    z-index: 2;
    max-height: 28px;
    margin-top: 1px;
    width: 12px;

    fill: var(--gold-3);
    transform: rotate(90deg);
}

.nav-side {
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;

    -webkit-align-items: center;
    align-items: center;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;

    -webkit-flex: 0 1 auto;

    padding: 0 50px;

    flex: 0 1 auto;
    border: 2px solid var(--gold-5);
    border-bottom: 0;
    border-top: 0;
    height: 100%;
}

.nav-main>* {
    margin: 0 25px 0 0;

    font-family: "Beaufort Bold", serif;
    text-transform: uppercase;
    color: var(--gold-4);
    font-size: 0.875em;
}

.nav-main a {
    text-decoration: none;
}

/*-----------------------------------------------------------------------------------------Tactical + Combat Card */

.combat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tactical-container {
    flex: 1;
    margin: 0 10px;
    font-family: 'Beaufort Bold', Arial, sans-serif;
    color: var(--gold-1);
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.tactical-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--grey-1);
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;

    margin: 0 0 5px 0;
}

/* Style Slide */
.style-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    color: var(--grey-1);
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;

    margin: 0 0 5px 0;
}

.style-slide {
    background-color: transparent;
    position: relative;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;

    height: 15px;
    width: 150px;
    margin: 10px auto 5px auto;
}

.style-icon {
    fill: #a89c8c;
}

.style-lit {
    fill: #f8e4d4
}

.style-pin {
    position: absolute;
    width: 12px;
    height: 12px;

    transform: translateX(20px);
}

.style-slide .style-basic path {
    fill: #a09a8b;
}

/* Difficulty Bar */
.difficulty-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--grey-1);
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;

    margin: 0 0 5px 0;
}

.difficulty-bar {
    width: 150px;
    margin: 5px auto 0 auto;
    position: relative;
}

.difficulty-svg {
    position: absolute;
    bottom: 2px;
}

.difficulty-bg {
    width: 150px;
}

/* Combat Wheel */
.combat-wheel {
    flex: 1;
    width: 55%;
    margin: 10px auto;
}

.combat-svg {
    display: block;
    margin: auto;

    max-width: 100%;
    max-height: 100%;

    overflow: visible;
}

.combat-icon {
    fill: #a89c8c;
}

.combat-middle {
    fill: #0097ac
}

.combat-stat {
    fill: #00202d;
}

.combat-stat .combat-bar:nth-of-type(1) {
    fill: #0097ac
}

.combat-stat .combat-bar:nth-of-type(2) {
    fill: #70b6c2
}

.combat-stat .combat-bar:nth-of-type(3) {
    fill: #b0d9db;
}

.combat-hover:hover {
    fill-opacity: 0
}

.combat-section:hover .combat-bar:nth-of-type(1) {
    fill: #00c6e0
}

.combat-section:hover .combat-bar:nth-of-type(2) {
    fill: #94c8d1
}

.combat-section:hover .combat-bar:nth-of-type(3) {
    fill: #d2e9ea
}

.combat-section:hover .combat-icon {
    fill: #f8e4d4;
    transform: scale(1.1);
    transform-origin: 50% 50%
}


/* SWIPER JS OVERRIDE CSS */

.swiper-slide {
    width: 125px !important;
}

/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--gold-3);
    opacity: 1;
    border: 2px solid var(--gold-3);
}

/* target all bullets */
.swiper-pagination-bullet {
    opacity: 1;
    border: 2px solid var(--gold-3);
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical>.swiper-pagination-bullets {
    right: -25% !important;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
}


/* Loading Component */
.loadSpin-Container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #020a13;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loadingSpin {
    position: absolute;

}

.loadCenter {
    border: 3px solid #897344;

    display: block;
    height: 40px;
    width: 40px;

    transform: rotate(45deg);
    animation: spinCenter 6s cubic-bezier(0.175, 0.885, 0.32, 1) infinite;
    /* spin-snap effect */
}

.loadCircle {
    animation: spin 9s linear infinite;
}

.loadLeft svg:nth-child(1) {
    position: absolute;
    top: 8%;
    left: -38%;
    transform: rotate(90deg);

    stroke-dasharray: 116;
    stroke-dashoffset: 0;
    animation: lineFill 3s ease-in-out infinite;
}

.loadLeft svg:last-child {
    position: absolute;
    top: -36%;
    left: -126%;
    transform: rotate(90deg);

    stroke-dasharray: 235;
    stroke-dashoffset: 0;
    animation: lineFill2 3s ease-in-out infinite;
}

.loadRight svg:nth-child(1) {
    position: absolute;
    top: 8%;
    left: 48%;
    transform: rotate(270deg);

    stroke-dasharray: 116;
    stroke-dashoffset: 0;
    animation: lineFill 3s ease-in-out infinite;
}

.loadRight svg:last-child {
    position: absolute;
    top: -36%;
    left: 48%;
    transform: rotate(270deg);

    stroke-dasharray: 235;
    stroke-dashoffset: 0;
    animation: lineFill2 3s ease-in-out infinite;
}

@keyframes lineFill {
    0% {
        stroke-dashoffset: 116;
    }

    25% {
        stroke-dashoffset: 232;
    }

    50% {
        stroke-dashoffset: 348;
    }

    75% {
        stroke-dashoffset: 348;
    }

    100% {
        stroke-dashoffset: 348;
    }
}

@keyframes lineFill2 {
    0% {
        stroke-dashoffset: 235;
    }

    33% {
        stroke-dashoffset: 470;
    }

    66% {
        stroke-dashoffset: 705;
    }

    100% {
        stroke-dashoffset: 705;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinCenter {
    0% {
        transform: rotate(45deg);
    }

    25% {
        transform: rotate(135deg);
    }

    50% {
        transform: rotate(225deg);
    }

    75% {
        transform: rotate(315deg);
    }

    100% {
        transform: rotate(405deg);
    }
}

.loadSpin-Container.hidden {
    /* animation: fade-out 1s ease-out; */
    z-index: -100;
}

.loadSpin-Container.show {
    /* animation: fade-in ease-in; */
    z-index: 99;
}

@keyframes fade-out {
    0% { opacity: 1; }

    100% { opacity: 0; }
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.champComponent.show {
    animation: fade-in .5s ease-out both;
}

.champComponent.hidden {
    opacity: 0;
}