.champ-card {
    position: relative;
    margin: 5px;
    flex: 1 1 18%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    /* box-sizing: border-box; */

    border-image: linear-gradient(var(--gold-2), var(--gold-3)) 30;  /* border-image: linear-gradient(#c8a96c, #7b5c29) 30;  */
    border-width: 3px;
    border-style: solid;

    filter: brightness(50%);
    font-family: 'Beaufort Bold', Arial, sans-serif;
    text-decoration: none;

    width: 228px;
    height: 414px;
    aspect-ratio: 228 / 414;
}

.card-image {
    position: absolute;
    inset: 0;
    margin: auto;
    
    transform:  scale(1);;
    overflow: hidden;
    transition: all .5s cubic-bezier(0.165, 0.84, 0.44, 1);

    object-fit: cover;

    width: 228px;
    height: 414px;
    aspect-ratio: 228 / 414;

    /* scale: 1; */
}

.card-image img {
    position: absolute;
}

.champ-card:hover {
    /* transform: scale(.96); */
    /* transform: scale(1.05); */
    /* position: relative; */
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    filter: brightness(100%);
}

.champ-card:hover .card-image {
    transform: scale(1.035); 
    /* scale: 1.035; */
}

.card-info {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(10, 10, 12, .5);
    border-top: 1px solid rgba(10, 10, 12, .75);
    color: var(--gold-4);
    height: 40px;
    /* overflow: hidden; */
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.champ-card:hover .card-info {
    height: 80px;
    /*     overflow: hidden; */
}


.champ-card:hover .champ-part {
    padding: 12px 12px;
}

.champ-card:hover .champ-part .champ-region {
    display: block;
}

.champ-card:hover .champ-part .champ-name {
    color: var(--gold-3);
}

/* *REGION & CHAMP NAME TAG */
.champ-part {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    

    position: relative;
    z-index: 5;

    background-color:  rgba(30, 35, 40, .9) ;
    border-top: 2px solid var(--hextech-black-2);

    
    transition: .2s ease-in-out all;
}

.champ-region {
    display: none;
    position: absolute;

    top: -25%;
    left: 50%;
    transform: translateX(-50%); 

    object-fit: contain;
    
    height: 2em;
    width: 2em;
    background-color: rgba(30, 35, 40, .9);
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.4);

    border-radius: 50%;
    border: 1px solid var(--hextech-black-2);

    padding: 2px;
    transition:  .2s ease-in-out all;
}

.champ-name {
    font-size: 1em;
    padding: .5em;

    color: var(--gold-4);
    letter-spacing: 2px;
    width: 100%;
    text-decoration: none;

    text-transform: uppercase;
    white-space: wrap;
}

.champ-title {
    display: none;
    text-decoration: none;
    font-size: .75em;
    color: var(--grey-1);
    width: 100%;
    white-space: nowrap;
    text-transform: uppercase;
    transition: .3s ease-in-out all;
}

.champ-card:hover .champ-title {
    transition: .6s ease-in-out all;
    display: initial;
}

/* * Border Effect - Uncomment if may designated color na for each champion  */
/* .champ-card:hover {
    -webkit-box-shadow: 0px 0px 10px 0px #f16561;
    -moz-box-shadow: 0px 0px 10px 0px rgba(255,165,0,1);
    box-shadow: 0px 0px 15px 0px var(--ct); 
    cursor: url('https://raw.communitydragon.org/pbe/game/assets/ux/cursors/hand2.png') 0 0, auto;
}*/

@media (max-width: 1280px) {
    .champ-card {
        flex: 1 1 20%;
        height: auto;
    }
}

@media (max-width: 720px) {
    .champ-card {
        flex: 1 1 25%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .champ-card {
        flex: 1 1 33%;
        height: auto;
    }
}