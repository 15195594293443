@font-face {
  font-family: 'Beaufort Regular';
  src: local('Beaufort'), url(../fonts//BeaufortForLoL-OTF/BeaufortforLOL-Regular.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Beaufort Bold';
  src: local('Beaufort'), url(../fonts/BeaufortForLoL-OTF/BeaufortforLOL-Bold.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: 'Spiegel';
  src: local('Spiegel'), url(../fonts/Spiegel-OTF/Spiegel-Regular.otf) format("opentype");
  font-display: swap;
}

* {
  margin: 0px;
  padding: 0px;
}

:root {
  --goldText: #937341;

  /* Hextech Magic Color */
  --blue-1: #CDFAFA;
  --blue-2: #0AC8B9; /* Magic (Blue Gradient): blue-4 -> blue-2*/
  --blue-3: #0397AB;
  --blue-4: #005A82;
  --blue-5: #0A323C;
  /* Background Application (Dark Blue Gradient)*/
  --blue-6: #091428;
  --blue-7: #0A1428;

  /* Hextech Metal Gold */
  --gold-1:#F0E6D2; /* --mildText: #c4b998; --whiteText: #fff8df; */
  --gold-2:#C8AA6E;
  /* GoldGradient: gold-4 -> gold-3 */
  --gold-3:#C89B3C; 
  --gold-4:#785A28;
  --gold-5:#463714; /* --borderColorPrimary: #31271e; */
  --gold-6:#32281E;

  --grey-1:#A09B8C;
  --grey-mid:#5B5A56;
  --grey-2:#3C3C41;
  --grey-3:#1E2328;
  --grey-cool: #1E282D;
  --hextech-black:#010A13; /* --bgColorPrimary: #111318; */
  --hextech-black-2:#111318;
  --hextech-black-3: #0a0a0c;

  /* Home */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  /* background-color: #020a13;*/
  background: linear-gradient(180deg, rgba(10, 10, 12, 1) 50%, rgba(2, 10, 19, 1) 100%);
  
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

body {
  font-family: 'Beaufort Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* cursor:  url('https://raw.communitydragon.org/pbe/game/assets/ux/cursors/hand1.png') 0 0, auto; */
}

html,
body{
  height: 100%;
  /* Enables smooth scrolling */
  scroll-snap-type: y mandatory; 
  overflow-y: scroll;
  scroll-behavior: smooth; 
}

/* Remove Scrollbar  */
::-webkit-scrollbar {
  display: none;
}

/* *SCROLLBAR FOR CHAMPIONLIST */
/* .card-container {
  overflow-y: auto;
  height: 50vh;
}

.card-container::-webkit-scrollbar {
  display: block;
  width: .5vw;
}

.card-container::-webkit-scrollbar-track {
  background-color: #121212;
}

.card-container::-webkit-scrollbar-track:hover {
  background-color: #111318;
}

.card-container::-webkit-scrollbar-thumb {
  background-color: #937341;
  border-radius: 10px;
}

.card-container::-webkit-scrollbar-thumb:hover {
  background-color: #6b521e;
}  */

code {
  font-family: 'Beaufort', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* a,button:hover{
  //cursor: url('https://raw.communitydragon.org/pbe/game/assets/ux/cursors/hand2.png') 0 0, auto;
}*/